import classNames from 'classnames';
import {
	AnchorHTMLAttributes,
	MouseEventHandler,
	PropsWithChildren,
	useCallback,
	useMemo
} from 'react';

import { useLinkComponent } from '../../contexts';

import styles from './styles.module.scss';

export type LinkProps = {
	disabled?: boolean;
	prefetch?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = ({
	href,
	children,
	disabled = false,
	onClick,
	className,
	...otherProps
}: PropsWithChildren<LinkProps>) => {
	const Component = useLinkComponent();

	const onClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement>>(
		(event) => {
			if (disabled) {
				event.preventDefault();
				return;
			}

			onClick?.(event);
		},
		[disabled, onClick]
	);

	const isClickable = useMemo(
		() => (href !== undefined || onClick !== undefined) && !disabled,
		[href, onClick, disabled]
	);

	return (
		<Component
			onClick={onClickHandler}
			aria-disabled={disabled}
			className={classNames(className, {
				[styles.Clickable]: isClickable,
				[styles.Disabled]: disabled
			})}
			{...otherProps}
			href={!disabled ? href : undefined}>
			{children}
		</Component>
	);
};

export default Link;
