import {
	EActionButtonType,
	EIllustrationName,
	KvActionButtonText,
	KvIllustration
} from '@kelvininc/react-ui-components';
import classNames from 'classnames';
import { isString } from 'lodash-es';
import { ComponentProps, ReactNode } from 'react';

import { Link } from '../Link';

import { DEFAULT_ERROR_DESCRIPTION, DEFAULT_ERROR_TITLE } from './config';
import styles from './styles.module.scss';

type ErrorFallbackProps = {
	title?: ReactNode;
	description?: ReactNode;
	illustration?: EIllustrationName;
	fullscreen?: boolean;
	buttonConfig?: Omit<ComponentProps<typeof KvActionButtonText>, 'type'> & {
		type?: EActionButtonType;
	};
	linkConfig?: ComponentProps<typeof Link>;
};

export const ErrorFallback = ({
	title = DEFAULT_ERROR_TITLE,
	description = DEFAULT_ERROR_DESCRIPTION,
	illustration = EIllustrationName.ErrorState,
	fullscreen = true,
	buttonConfig,
	linkConfig
}: ErrorFallbackProps) => {
	return (
		<div className={classNames(styles.Container, { [styles.ContainerFullscreen]: fullscreen })}>
			<div className={styles.Illustration}>
				<KvIllustration name={illustration} />
			</div>
			<div className={styles.Title}>{isString(title) ? <h1>{title}</h1> : title}</div>
			<div className={styles.Description}>
				{isString(description) ? <h3>{description}</h3> : description}
			</div>
			{buttonConfig && (
				<Link {...linkConfig}>
					<KvActionButtonText {...buttonConfig} type={EActionButtonType.Primary} />
				</Link>
			)}
		</div>
	);
};
