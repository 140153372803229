import styles from './styles.module.scss';

type TitleProps = {
	message: string;
};

export const Title = ({ message }: TitleProps) => (
	<h1>
		<span className={styles.TitleEmphasis}>Oops!</span> {message}
	</h1>
);
