import {
	AnchorHTMLAttributes,
	ElementType,
	PropsWithChildren,
	createContext,
	useContext
} from 'react';

import { LinkContextValues } from './types';

export const LinkContext = createContext<LinkContextValues | null>(null);

type LinkContextProviderProps = {
	Component: ElementType<AnchorHTMLAttributes<HTMLAnchorElement>>;
};

export const LinkContextProvider = ({
	Component,
	children
}: PropsWithChildren<LinkContextProviderProps>) => {
	return <LinkContext.Provider value={{ Component }}>{children}</LinkContext.Provider>;
};

export const useLinkComponent = (): ElementType<AnchorHTMLAttributes<HTMLAnchorElement>> => {
	const { Component = 'a' } = useContext(LinkContext) ?? {};

	return Component;
};
