import { EIllustrationName } from '@kelvininc/react-ui-components';

import { ErrorFallback } from '../ErrorFallback';

import { Description, Title } from './components';
import { ACTION_TEXT } from './config';

type NotFoundProps = {
	title: string;
	description: string;
};

export const NotFound = ({ title, description }: NotFoundProps) => {
	return (
		<ErrorFallback
			title={<Title message={title} />}
			description={description && <Description message={description} />}
			illustration={EIllustrationName.ErrorState404}
			buttonConfig={{ text: ACTION_TEXT }}
			linkConfig={{ href: '/' }}
		/>
	);
};
